import BackOff from '../BackOff.vue';
export default {
  name: 'Information',
  components: {
    BackOff
  },
  data() {
    return {
      backUrl: '/home'
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created: async function () {},
  methods: {}
};